import React from "react";
import {Typography, Grid, Button, useTheme} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";

interface EmptyStateBlockProps {
    icon:SvgIconComponent
    header:string
    subHeaders?:string[]
    primaryAction?:ActionButtonProps
}

interface ActionButtonProps {
    onClick:(args:React.MouseEvent) => void
    label:string
    startIcon:React.ReactElement
    disabled?:boolean
}

/**
 * EmptyStateBlock
 * @param {EmptyStateBlockProps} props
 * @return {React.ReactElement}
 */
function EmptyStateBlock({icon: Icon, ...props}: EmptyStateBlockProps): React.ReactElement {
    const theme=useTheme();
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" gap={2} sx={{height: "100%"}}>
            {/* Icon */}
            <Grid item><Icon sx={{width: "150px", height: "150px", color: theme.palette.primary.main}} /></Grid>

            {/* Header */}
            <Grid item><Typography variant="h4">{props.header}</Typography></Grid>

            {/* SubHeader container */}
            {props.subHeaders&&(
                <Grid container direction="column" alignItems="center">
                    {/* SubHeaders */}
                    {props.subHeaders.map((subHeader) => (
                        <Grid item key={subHeader}><Typography variant="body1" fontWeight="500">{subHeader}</Typography></Grid>
                    ))}
                </Grid>
            )}

            {/* Action Button container */}
            {(props.primaryAction)&&(
                <Grid item>
                    <Grid container gap={2}>
                        {props.primaryAction&&(
                            <Grid item>
                                <Button variant="contained" size="large" sx={{textTransform: "none", fontWeight: "500"}} startIcon={props.primaryAction.startIcon} onClick={props.primaryAction.onClick}>
                                    {props.primaryAction.label}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default EmptyStateBlock;
