import React from "react";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import {Button, Dialog as DialogBase, DialogTitle, DialogContent, DialogActions, Typography} from "@mui/material";
import {DialogType, Action} from "../../types";
import {useContext} from "./Context";

interface Props {
    dialog:DialogType
    status:DialogType
    title:string
    content:React.ReactElement|React.ReactElement[]
    actions:Action[]
    maxWidth?:"xl"|"lg"|"md"|"sm"|"xs"
}

/**
 * Dialog
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Dialog(props:Props):React.ReactElement {
    const {logout}:Auth0ContextInterface<User>= useAuth0();
    const context=useContext();
    const content=(
        <>
            <DialogTitle>
                <Typography sx={{fontSize: "20px", fontWeight: "700", lineHeight: "160%"}}>
                    {props.title}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{borderBlock: "solid 1px rgba(0, 0, 0, 0.12)"}} style={{padding: "16px 24px"}}>
                {props.content}
            </DialogContent>
            <DialogActions>
                {/* note: order matters. last button is always contained variant */}
                {props.actions.map((action:Action, idx:number) => (
                    <Button
                        onClick={
                            props.actions.length-1===idx && context.state.to==="/?signout=false"
                                ?(args:React.MouseEvent):Promise<void> => logout({logoutParams: {returnTo: `${window.location.origin}/?signout=true`}})
                                :action.onClick
                        }
                        key={action.label}
                        variant={idx===props.actions.length-1?"contained":"text"}
                        sx={{textTransform: "capitalize"}}
                    >
                        {action.label}
                    </Button>
                ))}
            </DialogActions>
        </>
    );
    // TODO: missing onClose prop
    return (
        <DialogBase open={props.status===props.dialog} maxWidth={props.maxWidth||"xs"}>
            {content}
        </DialogBase>
    );
}

export default Dialog;
