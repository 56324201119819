import {Box, Link, List, ListItem, Stack, Typography} from "@mui/material";
import React from "react";
import {MAIN_NAVIGATION} from "./MenuBar";
import OverLineTitle from "./OverLineTitle";
import {REPORTING_LEVEL, TIMESCALE, DATA_DICTIONARY_URL} from "../../config";
import {NavigationItemType} from "../../types";

const Faq: NavigationItemType = MAIN_NAVIGATION.find((item) => item.key === "FAQ")!;
const BasicDataExportLabel = MAIN_NAVIGATION.find((item) => item.key === "DATA_EXPORT")!.subMenu!.find((item) => item.key === "BASIC_DATA_EXPORT")?.label;
const AdvancedDataExportLabel = MAIN_NAVIGATION.find((item) => item.key === "DATA_EXPORT")!.subMenu!.find((item) => item.key === "ADVANCED_DATA_EXPORT")?.label;
const Email = "energybilling@dcas.nyc.gov";

const REPORTING_LEVEL_CONTENT = [
    {key: "meter", content: ": Search by Account-Meter"},
    {key: "property", content: ": Search by BDBID or property address"},
    {key: "agency", content: ": Select one or more City agencies"}];
const TIMESCALE_CONTENT = [
    {key: "monthly", content: ": Report data on a monthly basis for all months with energy consumption during the time period of July 2013 through December 2023*."},
    {key: "annual", content: ": Report data by calendar year or fiscal year for all years with energy consumption during the time period of July 2013 through December 2023*."},
];

const ListData = [
    {
        header: "Reporting Level:",
        listItems: REPORTING_LEVEL_CONTENT,
    },
    {
        header: "Timescale:",
        listItems: TIMESCALE_CONTENT,
    },
];
const ParagraphData = [
    {
        header: "Calendarization:",
        paragraph: (
            <Typography>
                Data provided by this
                {" "}
                {BasicDataExportLabel}
                {" "}
                is
                {" \""}
                <Link href={`${Faq.path}#calendarization`} target="_blank" key="faq-link">calendarized</Link>
                {"\""}
                , which means that energy consumption is prorated to ensure that monthly reporting periods consistently range from the first day to the last day of each month.
                Please note that this calendarized dataset will not match EC3 utility billing data because monthly billing periods sometimes do not span from the first day to the last day of each month.
                The forthcoming
                {" "}
                <Typography component="span" sx={{fontWeight: 700}}>{AdvancedDataExportLabel}</Typography>
                {" "}
                will give the option to calendarize the data or export raw billing data.
            </Typography>
        ),
    },
    {
        header: Faq.label,
        paragraph: (
            <Typography>
                For support on how to use this tool, please visit our
                {" "}
                <Link href={Faq.path} target="_blank" key="faq-link">{Faq.label}</Link>
                {" "}
                page or download our
                {" "}
                <Link href={DATA_DICTIONARY_URL} download>data dictionary (.xlsx)</Link>
                .
            </Typography>
        ),
    },
];

/**
 * Header
 * @return {React.ReactElement}
 */
function Header(props:{children:React.ReactNode}):React.ReactElement {
    return (
        <Typography sx={{fontWeight: 700}}>{props.children}</Typography>
    );
}

/**
 * BasicDataExportIntro
 * @return {React.ReactElement}
 */
function BasicDataExportIntro():React.ReactElement {
    return (
        <Stack gap={3}>
            <Box>
                <OverLineTitle title={BasicDataExportLabel || ""} />
                <Typography sx={{marginTop: "8px"}}>
                    {`Welcome to the ${BasicDataExportLabel}.`}
                    This tool allows you to extract energy consumption data for meters, properties, or agencies.
                    Choose your search criteria and timescale below to generate a customized dataset, exported as a .csv.
                </Typography>
            </Box>
            {/* Reporting level, TimeScale */}
            {ListData.map((list) => (
                <Box key={list.header}>
                    <Header>{list.header}</Header>
                    <List sx={{listStyleType: "disc", padding: 0, paddingLeft: 3, "& .MuiListItem-root": {display: "list-item"}}}>
                        {list.listItems.map((listItem) => (
                            <ListItem key={listItem.key} sx={{padding: 0}}>
                                <Typography component="span" sx={{textDecoration: "underline"}}>{[...REPORTING_LEVEL, ...TIMESCALE].find((item) => item.key === listItem.key)?.label}</Typography>
                                <Typography component="span">{listItem.content}</Typography>
                            </ListItem>
                        ))}
                    </List>

                </Box>

            ))}
            {/* Calendarization, Frequently Asked Questions */}
            {ParagraphData.map((data) => (
                <Box key={data.header}>
                    <Header>{data.header}</Header>
                    {data.paragraph}
                </Box>
            ))}

            <Box>
                <Typography fontSize={14}>*Subject to change.</Typography>
            </Box>
            {/* Note */}
            <Box>
                <Typography fontSize={14} fontStyle="italic" fontWeight={700} lineHeight="20px" letterSpacing="0.17px">
                    Note: Billed energy charges and energy usage amounts are based on meter reads that can occur at different times of the month for a particular utility account and charges vary by utility provider.
                    Billed charges are also comprised of utility managed meters only.
                    Data contained herein may not match billed charges or energy values in their entirety due to differences in monthly billing cycles, calendarized dates, or the inclusion of submetered data that is not read by the utility.
                    Please use EC3 or contact DEM’s energy billing and budget team at
                    {" "}
                    <Link href={`mailto:${Email}`}>{Email}</Link>
                    {" "}
                    for specific questions about billed energy charges or energy usage amounts
                </Typography>
            </Box>
        </Stack>
    );
}

export default BasicDataExportIntro;
