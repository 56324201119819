import React from "react";
import {DataGrid as DataGridBase, GridColDef, gridPageCountSelector, GridPagination, GridRenderCellParams, GridRowId, GridRowModel, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import {Delete, RestartAlt, ArrowBack, ArrowForward} from "@mui/icons-material/";
import MuiPagination from "@mui/material/Pagination";
import {TablePaginationProps} from "@mui/material/TablePagination";
import {Box, Button, PaginationItem, Typography, useTheme, Theme} from "@mui/material";
import {GREY} from "../../config";

declare module "@mui/x-data-grid" {
    interface NoRowsOverlayPropsOverrides {
      label: string;
    }
  }

  interface DataGridProps{
    column:GridColDef[]
    rows:any
    title: string
    disabled?:boolean
    onRowDelete:(row:any) => void
    onRowsDelete:() => void
    getRowId?:(row:GridRowModel) => GridRowId
}

/**
 * CustomNoRowsOverlay
 * @param {any} props
 * @return {React.ReactElement}
 */
function CustomNoRowsOverlay(props:any):React.ReactElement {
    // Hide the displayed rows, and rows per page UI.
    return (
        <Box sx={{display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
            <Typography fontSize={20}>
                {`No ${props.label} have been included in the report. Add ${props.label} above to view them here.`}
            </Typography>
        </Box>
    );
}

/**
 * PreviousButton
 * @return {React.ReactElement}
 */
function PreviousButton():React.ReactElement {
    return (
        <Box display="flex" sx={{flexDirection: "reverse", marginRight: "12px"}}>
            <ArrowBack fontSize="small" />
            <Typography sx={{marginLeft: "6px"}} fontSize={15}>Prev</Typography>
        </Box>
    );
}

/**
 * NextButton
 * @return {React.ReactElement}
 */
function NextButton():React.ReactElement {
    return (
        <Box display="flex" sx={{marginLeft: "12px"}}>
            <Typography sx={{marginRight: "6px"}} fontSize={15}>Next</Typography>
            <ArrowForward fontSize="small" />
        </Box>
    );
}

/**
 * Pagination
 * @param {TablePaginationProps} props
 * @return {React.ReactElement}
 */
function Pagination(props:TablePaginationProps):React.ReactElement {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            size="small"
            color="primary" // Needs to use color from theme.
            className={props.className}
            count={pageCount}
            page={props.page + 1}
            shape="rounded"
            variant="outlined"
            onChange={(event, newPage) => {
                props.onPageChange(event as any, newPage - 1);
            }}
            renderItem={(item) => (
                <PaginationItem
                    slots={{previous: PreviousButton, next: NextButton}}
                    {...item}
                />
            )}
        />
    );
}

/**
 * CustomPagination
 * @param {any} props
 * @return {React.ReactElement}
 */
function CustomPagination(props:any):React.ReactElement {
    // Hide the displayed rows, and rows per page UI.
    return <GridPagination labelDisplayedRows={() => null} rowsPerPageOptions={[]} ActionsComponent={Pagination} {...props} />;
}

/**
 * DataGrid
 * @param DataGridProps props
 * @return {React.ReactElement}
 */
function DataGrid(props:DataGridProps):React.ReactElement {
    const theme:Theme=useTheme();
    // Re-usable delete property column.
    const DELETE_COLUMN_DEFINITION:GridColDef={
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params:GridRenderCellParams) => (
            <Button
                disabled={props.disabled}
                onClick={() => props.onRowDelete(params.row)}
                sx={{
                    padding: "4px",
                    border: "1px solid #182A3380",
                    minWidth: "20px",
                    width: "24px",
                    height: "24px",
                    borderRadius: "1px",
                }}
            >
                <Delete sx={{width: "100%", height: "100%", color: "#182A3380"}} />
            </Button>
        ),

    };

    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px"}}>
                <Typography fontSize={24} fontWeight={700} lineHeight="32px">
                    {` Selected ${props.title} `}
                </Typography>
                <Button disabled={props.disabled || !props.rows.length} onClick={props.onRowsDelete} sx={{textTransform: "none", fontWeight: 500}} color="error" endIcon={<RestartAlt />} variant="outlined" size="large">
                    { `Clear All Selected ${props.title}` }
                </Button>
            </Box>
            <Box sx={{height: "400px", width: "100%"}}>
                <DataGridBase
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: {pageSize: 8},
                        },
                    }}
                    slots={{
                        pagination: CustomPagination,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        noRowsOverlay: {label: props.title},
                    }}
                    rows={props.rows}
                    pageSizeOptions={[8]}
                    rowHeight={36}
                    columns={[DELETE_COLUMN_DEFINITION, ...props.column]}
                    disableRowSelectionOnClick
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnResize
                    getRowId={props.getRowId}
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "15px 15px 0 0",
                        border: "none",
                        ".MuiDataGrid-main": {
                            borderRadius: "15px",
                            border: "1px solid #BAC6C8",
                        },
                        ".MuiDataGrid-columnHeader": {
                            backgroundColor: props.disabled ? GREY : theme.palette.primary.main,
                            color: "#fff",
                            height: "40px !important",
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                            fontWeight: 700,
                            fontSize: "12px",
                            textTransform: "uppercase",
                        },
                        ".MuiDataGrid-cell:focus": {
                            outline: "none",
                        },
                        ".MuiDataGrid-footerContainer": {
                            justifyContent: "flex-start",
                            border: "none",
                        },
                        ".MuiTablePagination-toolbar, .MuiTablePagination-actions": {
                            paddingLeft: "0",
                            marginLeft: "0 !important",
                        },
                        ".MuiPaginationItem-previousNext": {
                            border: "none",
                        },
                        ".MuiPaginationItem-root": {
                            backgroundColor: "#fff !important",
                        },
                        ".MuiPaginationItem-root.Mui-selected": {
                            color: props.disabled ? GREY : theme.palette.primary.main,
                            borderColor: props.disabled ? GREY : theme.palette.primary.main,
                        },
                        "[data-field=delete]": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        },
                        ".MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        },
                        ".MuiPagination-ul .MuiPaginationItem-root": {
                            borderColor: "white",
                            color: props.disabled ? GREY : theme.palette.primary.main,
                        },
                        ".MuiPagination-ul .MuiPaginationItem-root.Mui-selected ": {
                            borderColor: theme.palette.primary.main,
                        },
                        a: {
                            fontWeight: 700,
                            color: props.disabled ? GREY : theme.palette.primary.main,
                            textDecoration: "none",
                        },
                    }}
                />
            </Box>
        </>
    );
}

export default DataGrid;
