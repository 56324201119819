import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import "./index.css";
import Routes from "./views/Routes";
import reportWebVitals from "./reportWebVitals";
import Auth0Provider from "./components/generics/Auth0Provider";

/* eslint-disable import/no-extraneous-dependencies */
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Router>
        <Auth0Provider>
            <Routes />
        </Auth0Provider>
    </Router>,
);

reportWebVitals();
