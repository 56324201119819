import React from "react";
import {Alert as AlertBase, AlertTitle, AlertColor} from "@mui/material";

interface Props{
    severity:AlertColor
    title:string
    body:string
}

/**
 * Alert
 * @return {React.ReactElement}
 */
function Alert(props:Props):React.ReactElement {
    return (
        <AlertBase variant="filled" severity={props.severity}>
            <AlertTitle>{props.title}</AlertTitle>
            {props.body}
        </AlertBase>
    );
}

export default Alert;
