import React, {useEffect, useState} from "react";
import {Accordion as AccordionBase, AccordionSummary, AccordionDetails, Box, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material/";
import {BLUE_LIGHT} from "../../config";

interface Content {
    id:string
    title:string
    children:React.ReactElement | React.ReactElement[]
}

interface Props {
    content:Content[]
}

/**
 * scrollTo
 * @param {string} to
 * @return {void}
 */
const scrollTo=(to:string):void => {
    const target:any = document.getElementById(to.replace("#", ""));
    if (!target) return;

    setTimeout(() => {
        const menuBar = document.getElementsByTagName("nav")[0];
        const menuBarHeight = menuBar?.clientHeight || 0;
        const y=(target?.getBoundingClientRect().top||0)+window.scrollY-menuBarHeight;
        window.scrollTo({top: y});
    }, 0);
};

/**
 * Accordion
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Accordion(props: Props):React.ReactElement {
    const [current, setCurrent] = useState<string|null>(null);

    useEffect(() => {
        const id=window.location.hash;
        if (id) scrollTo(id);
    }, []);

    return (
        <Box sx={{backgroundColor: BLUE_LIGHT, borderRadius: "16px", border: "solid 1px #C6D8E6", overflow: "hidden", paddingY: "8px"}}>
            {props.content.map((item:Content) => (
                <AccordionBase
                    disableGutters
                    id={item.id}
                    defaultExpanded={item.id === window.location.hash.slice(1)}
                    key={item.title}
                    sx={{
                        padding: "1px 16px",
                        backgroundColor: current === item.title ? "#E1EAF2!important" :"inherit",
                        // divider padding
                        "&::before": {
                            left: "16px",
                            right: "16px",
                        },
                        ":hover": {
                            backgroundColor: "#E9ECF1",
                        },
                    }}
                    style={{boxShadow: "none"}}
                    onChange={(e, expanded) => {
                        if (expanded) setCurrent(item.title);
                        else setCurrent(null);
                    }}
                >
                    <AccordionSummary expandIcon={<ExpandMore style={{color: "#0A5796", fontSize: 40}} />}>
                        <Typography sx={{fontSize: "20px", fontWeight: "700"}}>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {item.children}
                    </AccordionDetails>
                </AccordionBase>
            ))}
        </Box>
    );
}

export default Accordion;
