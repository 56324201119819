import React from "react";
import {Link, Typography, Paper, Box} from "@mui/material";
import Accordion from "../components/generics/Accordion";
import OverLineTitle from "../components/layout/OverLineTitle";
import {DATA_DICTIONARY_URL} from "../config";

const SUPPORT_EMAIL = "support@epsilon.nyc";

const FAQ_CONTENT=[
    {
        id: "data-export-format",
        title: "What format is the exported data in?",
        children: (
            <Typography>
                The exported data is in CSV (comma-separated values) format, which can be easily opened and analyzed in a spreadsheet application like Microsoft Excel or Google Sheets.
            </Typography>
        ),
    },
    {
        id: "data-download-types",
        title: "What types of data can I download?",
        children: (
            <Typography>
                With the Basic Data Export feature, all reports will include some identifying information about the meter, property, or agency of interest.
                They will also include the energy consumption for the period in native units, as well as conversions to a common energy unit (MMBtu) and greenhouse gas (GHG) impact in metric tons of CO2 equivalent (MTCO2e).
                For more information about the information included in each report, download the
                {" "}
                <Link href={DATA_DICTIONARY_URL} download>data dictionary (.xlsx)</Link>
                .
            </Typography>
        ),
    },
    {
        id: "customizing-data-export",
        title: "How can I customize the data export for my needs?",
        children: (
            <Typography>
                With the Basic Data Export feature, you can customize data by searching for and selecting multiple meters, properties, or agencies to add to the report.
                You can customize the report further by defining your timescale to report monthly data or an annual rollup.
                Then, for an annual report, you can select whether you want the data reported by Calendar Year or Fiscal Year.
            </Typography>
        ),
    },
    {
        id: "multi-entity-export",
        title: "Can I export data for multiple meters, properties, or agencies at once?",
        children: (
            <Typography>
                Yes, the application supports exporting data for multiple meters, properties, or agencies simultaneously.
            </Typography>
        ),
    },
    {
        id: "data-search-methods",
        title: "How can I search for data?",
        children: (
            <Typography>
                For a meter report, you can search by account-meter.
                <br />
                For a property report, you can search by BDBID or property address.
                <br />
                For an agency report, you can search by the agency’s acronym.
                <br />
                When searching by BDBID, you must include the complete BDBID to return results. All other fields allow for partial search.
            </Typography>
        ),
    },
    {
        id: "find-property",
        title: "What if I don’t know my BDBID or property address?",
        children: (
            <Typography>
                You can find your property’s BDBID or property address through DEM’s Salesforce Project Tracking System.
            </Typography>
        ),
    },
    {
        id: "find-meter",
        title: "What is an account-meter number and where can I find it?",
        children: (
            <Typography>
                The account-meter number is the unique identifier for all utility and non-utility energy meters.
                It is made by joining the meter’s account number with it’s meter code using a hyphen.
                For example, meter number 12345 under account number 123456789 has an account-meter of 123456789-12345.
                When searching for meters, you can use a partial account-meter number.
                This means that you can search by just the account number or just the meter code to return your results.
                <br />
                <br />
                Not sure of your account and/or meter number? Contact the support team at
                {" "}
                <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
                {" "}
                for a full list of account-meters in your agency.
            </Typography>
        ),
    },
    {
        id: "historical-data",
        title: "How far back can I export historical data?",
        children: (
            <Typography>
                Utility data (electricity, natural gas, and district steam) and non-utility data (fuel oils, propane, solar, etc.) are available starting July 1st, 2013.
            </Typography>
        ),
    },
    {
        id: "epsilon-ec3-differences",
        title: "How is Epsilon different from EC3?",
        children: (
            <Typography>
                EC3 is the source of truth for utility (i.e., electric, gas, and district steam) account information and billed energy charges.
                When you need information about your utility accounts or are seeking to review your agency’s energy bills, please continue to log in to EC3.
                <br />
                <br />
                Epsilon is designed to broaden and diversify data access through enabling users to obtain both utility and non-utility
                (e.g., fuel oil, on-site solar generation) energy consumption data that is calendarized and allocated to individual properties where the energy is consumed.
                When you are seeking to analyze total energy consumption and greenhouse gas (GHG) emissions from individual properties or groups of properties on regular time intervals, you will now be able to log in to Epsilon.
                Note that data contained in Epsilon may not match billed charges in EC3 due to differences between monthly utility billing cycles and the calendarized data presented in Epsilon.
            </Typography>
        ),
    },
    {
        id: "calendarization",
        title: "What is “calendarization”?",
        children: (
            <Typography>
                Calendarization involves categorizing utility bill usage by apportioning it to the specific month in which it occurred.
                This is achieved by first dividing the data into daily segments and then assigning the values to the corresponding calendar month.
                This process is particularly beneficial for comparing utility bill data month-to-month and year-to-year.
                Energy managers find calendarized data valuable because it allows them to clearly observed usage within the month it occurred, aiding in better management and decision-making.
                <br />
                <br />
                <Typography component="span" fontWeight={700}>Example:</Typography>
                {" "}
                A utility bill shows that a single meter used 1,000 kWh of electricity from July 23rd through August 18th and 800 kWh from August 19th through September 20th.
                What is the calendarized usage in August?
                <br />
                <br />
                The period from July 23rd through August 18th has 27 days.
                During this period, the meter records 37.04 kWh/day (1,000/27).
                This bill covers 18 days in August, so this bill’s apportioned usage for that month is 666.72 kWh (37.04 kWh/day * 18 days).
                <br />
                <br />
                The period from August 19th through September 20th has 33 days.
                During this period, the meter records 24.24 kWh/day (800 kWh/33 days).
                This bill covers 13 days in August, so this bill’s appointed usage for that month is 315.12 kWh (24.24 kWh/day * 13 days).
                <br />
                <br />
                The total calendarized usage for August is the sum of the apportioned usage from the first bill and the second bill.
                In this example, the usage for August 1st through August 31st is 981.84 kWh.
            </Typography>
        ),
    },
    {
        id: "meter-allocation",
        title: "What is meter allocation?",
        children: (
            <Typography>
                Meter allocation is a method of energy monitoring where a single meter, typically located at a central point, tracks and records energy usage for multiple buildings or spaces.
            </Typography>
        ),
    },
    {
        id: "associated-property",
        title: "What is a meter’s “associated property”?",
        children: (
            <Typography>
                The meter’s “associated property” is the property where the meter is physically located.
            </Typography>
        ),
    },
    {
        id: "property-level-calculation",
        title: "How is usage calculated for the property level?",
        children: (
            <Typography>
                When a new meter is added, it is determined whether or not it is a shared meter.
                If it is not shared, it is associated with a single BDBID at a 100% allocation rate.
                <br />
                <br />
                If it is shared across multiple BDBIDs, it is determined whether there is a known distribution of energy.
                This can be from sub-metering, engineering reports, building energy models, or agency estimates.
                In these cases, the meter is associated to the various BDBIDs with known allocation rates.
                <br />
                <br />
                If the meter is shared and the actual distribution of energy across properties is unknown, an estimate of allocation is calculated based on relative floor areas.
            </Typography>
        ),
    },
    {
        id: "data-update-frequency",
        title: "How often is the data updated?",
        children: (
            <Typography>
                The data update frequency depends on upstream sources, such as Salesforce and EC3.
                Users can generally expect new data approximately on a quarterly basis, though we aim to align with the collection schedules of these sources as closely as possible.
            </Typography>
        ),
    },
    {
        id: "contact-support",
        title: "How do I contact support if I encounter issues?",
        children: (
            <Typography>
                If you encounter issues or would like to provide feedback on the application, please email the support team at
                {" "}
                <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
                {" "}
                and include your name, a description of the issue, and screenshots that illustrate the problem.
            </Typography>
        ),
    },
];

/**
 * Faq
 * @return {React.ReactElement}
 */
function Faq():React.ReactElement {
    return (
        <Box>
            <OverLineTitle title="Frequently Asked Questions (FAQ)" />
            <Typography marginTop="8px">
                Welcome to the Frequently Asked Questions (FAQ) page.
                This page provides answers to common questions about the application, including its features, functionality, and usage.
                If you have any questions that are not covered here, please
                {" "}
                <Link href={`mailto:${SUPPORT_EMAIL}`}>contact our support team</Link>
                {" "}
                for further assistance.
            </Typography>
            <Paper sx={{padding: "48px", borderRadius: "16px", marginTop: "32px"}}>
                <Accordion content={FAQ_CONTENT} />
            </Paper>
        </Box>
    );
}

export default Faq;
