import React from "react";
import {Link as LinkBase, LinkProps as LinkPropsBase} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Link as ReactRouterLink} from "react-router-dom";

interface LinkProps extends LinkPropsBase {
    to:string
    state?:any
}

const StyledLink = styled(LinkBase)({
    color: "primary",
    textDecorationColor: "inherit",
}) as typeof LinkBase;

/**
 * Link
 * @param {LinkProps} props
 * @return {React.ReactElement}
 */
function Link(props: LinkProps):React.ReactElement {
    const {to, ...rest} = props;
    const isExternal = /^(http|https):\/\//.test(to);

    if (isExternal) {
        return <StyledLink href={to} {...rest} target="_blank" rel="noopener noreferrer" />;
    }

    return <StyledLink component={ReactRouterLink} to={to} {...rest} />;
}

export default Link;
