import {Box, Divider, Typography, Theme, useTheme} from "@mui/material";
import React from "react";

/**
 * OverLineTitle
 * @param {{title: string}} props
 * @return {React.ReactElement}
 */
function OverLineTitle(props:{title: string}):React.ReactElement {
    const theme:Theme=useTheme();
    return (
        <Box>
            <Divider sx={{
                borderColor: theme.palette.primary.main,
                borderBottomWidth: "3px", // stroke width
                width: 48,
                marginBottom: "8px",
            }}
            />
            <Typography color="text.primary" fontSize={24} fontWeight={700} lineHeight="28px">
                {props.title}
            </Typography>
        </Box>
    );
}

export default OverLineTitle;
