import React, {SetStateAction, useState, Dispatch, useEffect} from "react";
import {Box, Theme, Typography} from "@mui/material";
import {GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import moment from "moment";
// components
import {FieldErrors, FieldValues, useForm, UseFormReturn} from "react-hook-form";
import {AxiosError, AxiosResponse} from "axios";
import {yupResolver} from "@hookform/resolvers/yup";
import {enqueueSnackbar} from "notistack";
import * as yup from "yup";
import _ from "lodash";
import DataGrid from "../components/generics/DataGrid";
import {BasicExportForm} from "../components/forms";
import {Fields as DataExportFormFields} from "../components/forms/BasicExport";
import {Field, Selector} from "../components/generics/inputs";
import {useCall} from "../components/generics/useCall";
import {BASE_REPORTING_ROUTE, REPORTING_LEVEL_ROUTE, REPORTING_LEVEL as REPORTING_LEVEL_KEYS, TIMESCALE, YEAR_TYPE} from "../config";
import {alterFieldsViewMode, constructBasicDataPostConfig, formatBasicDataExportPayload, resolveOptionKeyLabel} from "../handlers";
import Alert from "../components/generics/Alert";
import Backdrop from "../components/generics/Backdrop";
import BasicDataExportIntro from "../components/layout/BasicDataExportIntro";
import Dialog from "../components/generics/Dialog";
import {useContext} from "../components/generics/Context";

const PROPERTY_COLUMNS:GridColDef[] = [
    {field: "property_name",
        headerName: "Property Name",
        flex: 2,
        renderCell: (params:GridRenderCellParams) => <Box sx={{fontWeight: "bold", color: (theme:Theme) => theme.palette.primary.main}}>{params.value}</Box>,
    },
    {field: "bdbid", headerName: "BDBID", flex: 0.5},
    // {field: "oecid", headerName: "OECID", flex: 1}, Note: Not on the API.
    {field: "organization_name", headerName: "Agency", flex: 1},
    {field: "recordtype", headerName: "Type", flex: 1},
    {field: "property_status", headerName: "Status", flex: 1},
    {field: "borough", headerName: "Borough", flex: 1},
    {field: "address", headerName: "Street Address", flex: 1.5},
];

const METER_COLUMNS:GridColDef[] = [
    {field: "dem_meter_code",
        headerName: "Meter Code",
        flex: 1,
        renderCell: (params:GridRenderCellParams) => <Box sx={{fontWeight: "bold", color: (theme:Theme) => theme.palette.primary.main}}>{params.value}</Box>,
    },
    {field: "account_number", headerName: "Account Number", flex: 1},
    {field: "beis_meter_id", headerName: "BEIS Meter ID", flex: 1},
    {field: "meter_type", headerName: "Meter Type", flex: 1.5},
    {field: "assoc_facility_bdbid", headerName: "Facility bdbid", flex: 1},
    {field: "assoc_facility_oecid", headerName: "Facility OECID", flex: 1},
    {field: "assoc_facility_street_address", headerName: "Facility address", flex: 1.5},
];

interface State{
    bucket:any[]
    reportDownloadResponse:AxiosResponse|AxiosError|undefined
    isSubmitDisabled:boolean
}

/**
 * BasicDataExport
 * @return {React.ReactElement}
 */
function BasicDataExport():React.ReactElement {
    const navigate=useNavigate();
    const context=useContext();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        bucket: [],
        reportDownloadResponse: undefined,
        isSubmitDisabled: true,
    });
    const [formFields, setFormFields]=useState<Field[]>(DataExportFormFields.filter((x) => ["reporting_level", "timescale"].includes(x.key)));
    const {get, call, loading}=useCall({state, setState});

    const formReturn:UseFormReturn=useForm({mode: "onSubmit", resolver: yupResolver(yup.object(formFields.reduce((a:any, v:any) => ({...a, [v.key]: v.yup}), {})))});
    const activeReportingLevel = resolveOptionKeyLabel(formReturn.getValues().reporting_level, REPORTING_LEVEL_KEYS)||"";

    /**
   * resolveFieldOptions
   * @param {AxiosResponse} data
   * @param {Field} field
   * @return {void}
   */
    const resolveFieldOptions=(data:AxiosResponse, field:Field):void => {
        if (field?.autocompleteOptions?.optionsHeader) {
            const keys = field?.autocompleteOptions?.optionsHeader.map((header:any) => header.key);
            field.autocompleteOptions.options=formatBasicDataExportPayload(data, keys); // eslint-disable-line no-param-reassign
            setFormFields((prevFields:Field[]) => {
                const clonedPrevFields = _.cloneDeep(prevFields);
                clonedPrevFields[prevFields.findIndex((f:Field) => f.key===field.key)]=field;
                return clonedPrevFields;
            });
        }
    };

    /**
   * onMeterSearch
   * @param {React.MouseEvent} args
   * @param {any} value
   * @return {Promise<void>}
   */
    const onMeterSearch=(field:Field) => async (args:React.MouseEvent, value:any):Promise<void> => {
        const METER_QUERY = `?account_meter__contains=${value}`;
        const response:AxiosResponse|AxiosError=await get(BASE_REPORTING_ROUTE, `${REPORTING_LEVEL_ROUTE.replace(/:reportingLevel/g, "meter")}${METER_QUERY}`);
        if (!(response instanceof AxiosError)) resolveFieldOptions(response, field);
        else enqueueSnackbar("Error Meter Fetch", {variant: "error"});
    };

    /**
     * onPropertySearch
     * @param {React.MouseEvent} args
     * @param {any} value
     * @param {Selector} selector
     * @return {Promise<void>}
     */
    const onPropertySearch=(field:Field) => async (args:React.MouseEvent, value:any, selector:Selector):Promise<void> => {
        const PROPERTY_QUERY = selector.key === "bdbid" ? `?bdbids=${value}` : `?address__contains=${value}`;
        const response:AxiosResponse|AxiosError=await get(BASE_REPORTING_ROUTE, `${REPORTING_LEVEL_ROUTE.replace(/:reportingLevel/g, "property")}${PROPERTY_QUERY}`);
        if (!(response instanceof AxiosError)) resolveFieldOptions(response, field);
        else enqueueSnackbar("Error Property Fetch", {variant: "error"});
    };

    /**
     * fetchAgencies
     * @param {Field} field
     * @return {Promise<void>}
     */
    const fetchAgencies=async (field:Field):Promise<void> => {
        if (field.autocompleteOptions?.options.length!==0) return;
        const response:AxiosResponse|AxiosError=await get(BASE_REPORTING_ROUTE, `${REPORTING_LEVEL_ROUTE.replace(/:reportingLevel/g, "agency")}`);
        if (!(response instanceof AxiosError)) {
            field.autocompleteOptions.options=response.data.results.map((i:any) => i.organization_name); // eslint-disable-line no-param-reassign
            setFormFields((prevFields:Field[]) => {
                const clonedPrevFields = _.cloneDeep(prevFields);
                clonedPrevFields[prevFields.findIndex((f:Field) => f.key===field.key)]=field;
                return clonedPrevFields;
            });
        } else enqueueSnackbar("Error Agency Fetch", {variant: "error"});
    };

    const REPORTING_LEVEL:any={
        meter: {
            title: "Meters",
            columns: METER_COLUMNS,
            onSearch: onMeterSearch,
            uniqueId: "account_meter",
            keys: METER_COLUMNS.map((k) => k.field),
        },
        property: {
            title: "Properties",
            columns: PROPERTY_COLUMNS,
            onSearch: onPropertySearch,
            uniqueId: "bdbid",
            keys: PROPERTY_COLUMNS.map((k) => k.field),
        },
        agency: {
            title: "Agency",
            columns: undefined,
            onSearch: undefined,
            uniqueId: undefined,
            keys: undefined,
        },
    };

    /**
     * removeFields
     * @param {string[]} keys
     * @return {void}
     */
    const removeFields=(keys:string[]):void => {
        setFormFields((prevFields:Field[]) => prevFields.filter((f:Field) => !keys.includes(f.key)));
    };

    /**
     * resolveSubmitStatus
     * @param {State} prevState
     * @param {FieldValues} values
     * @param {any[]} bucket
     * @return {void}
     */
    const resolveSubmitStatus=(prevState:State, values:FieldValues, bucket:any[]):State => {
        const REPORTING_LEVEL_VALUE = resolveOptionKeyLabel(values.reporting_level, REPORTING_LEVEL_KEYS) as string;
        const TIMESCALE_VALUE = resolveOptionKeyLabel(values.timescale, TIMESCALE);
        if ((Object.keys(REPORTING_LEVEL).includes(REPORTING_LEVEL_VALUE)
            && (bucket.length || values.agency?.length))) {
            if ((TIMESCALE_VALUE === "monthly") || (TIMESCALE_VALUE === "annual" && values.year_type)
            ) return ({...prevState, isSubmitDisabled: false, bucket});
            return ({...prevState, isSubmitDisabled: true, bucket});
        }
        return ({...prevState, isSubmitDisabled: true, bucket: []});
    };

    /**
     * resolveFormFields
     * @param {string} name
     * @param {number} index
     * @return {void}
     */
    const resolveFormFields=(name:string, index?:number):void => {
        if (!name) {
            removeFields(Object.keys(REPORTING_LEVEL));
            return;
        }

        // Find the selected input from Field[]
        const field:Field=DataExportFormFields.find((f:Field) => f.key===name) as Field;

        // overrirde EndAdornment when available OR GET agencies
        if (field.autocompleteOptions?.endAdornment) field.autocompleteOptions.endAdornment.onClick=REPORTING_LEVEL[field.key].onSearch(field);
        else if (field.key==="agency") fetchAgencies(field);

        // If the input already exists, exit to avoid duplicates.
        if (formFields.find((f:Field) => f.key===field.key)) return;

        // Merge the inputs, and sort based on Field[].
        setFormFields((prevFields:Field[]) => {
            let clone=_.cloneDeep(prevFields);
            // House cleaning
            if (Object.keys(REPORTING_LEVEL).includes(name)) clone=clone.filter((f:Field) => !Object.keys(REPORTING_LEVEL).filter((i:string) => i!==name).includes(f.key));
            clone.splice(index||clone.length, 0, field);
            return clone;
        });
    };

    useEffect(() => {
        const subscription = formReturn.watch(_.debounce((values:any, {name, type}:any) => {
            if (name === "reporting_level") {
                setState((prevState:State) => resolveSubmitStatus(prevState, values, []));
                resolveFormFields(resolveOptionKeyLabel(values.reporting_level, REPORTING_LEVEL_KEYS) as string, 1);
                formReturn.reset({reporting_level: values.reporting_level});
            } else if (name === "agency") {
                setState((prevState:State) => resolveSubmitStatus(prevState, values, []));
            } else if (name === "timescale") {
                if (resolveOptionKeyLabel(values.timescale, TIMESCALE)==="annual") resolveFormFields("year_type");
                else {
                    formReturn.reset(_.omit(values, ["year_type"]));
                    removeFields(["year_type"]);
                }
                setState((prevState:State) => resolveSubmitStatus(prevState, values, prevState.bucket));
            } else if (name === "year_type") {
                setState((prevState:State) => resolveSubmitStatus(prevState, values, prevState.bucket));
            }
        }, 0));
        return () => subscription.unsubscribe();
    });

    // un-register -- defaulting dialog to NONE
    useEffect(() => () => {
        if (context.state.dialog!=="NONE") context.setState({..._.cloneDeep(context.state), dialog: "NONE", to: undefined});
    });

    /**
     * onRowsDelete
     * @return {void}
     */
    const onRowsDelete=():void => setState((prevState:State) => resolveSubmitStatus(prevState, formReturn.getValues(), []));

    /**
     * onRowDelete
     * @param {string|number} id
     * @return {void}
     */
    const onRowDelete=(row:any):void => setState((prevState:State) => resolveSubmitStatus(prevState, formReturn.getValues(), state.bucket.filter((r:any) => r!==row)));

    /**
     * onDownloadSuccess
     * @param {AxiosResponse} res
     * @param {FieldValues} values
     * @return {void}
     */
    const onDownloadSuccess=(res:AxiosResponse, values:FieldValues):void => {
        setFormFields(alterFieldsViewMode({base: formFields}, "DISABLED_MODE", "disabled").base);
        const url=window.URL.createObjectURL(new Blob([res.data], {type: "text/csv"}));
        const link=document.createElement("a");
        const yearType=`${resolveOptionKeyLabel(values.timescale, TIMESCALE)==="annual"?`_${resolveOptionKeyLabel(values.year_type, YEAR_TYPE)==="calendar"?"CY":"FY"}`:""}`;
        const fileName = `Epsilon_${values.reporting_level}_${values.timescale}${yearType}_${moment().format("YYYY-MM-DD__hh-mm")}.csv`;
        link.href=url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /**
     * onDownload
     * @param {FieldValues} values
     * @return {Promise<void>}
     */
    const onDownload=async (values:FieldValues):Promise<void> => {
        const response=await call(constructBasicDataPostConfig(values, state.bucket.map((i:any) => i[REPORTING_LEVEL[REPORTING_LEVEL_KEYS.find((k:any) => k.label===values.reporting_level)?.key as string].uniqueId])));
        if (response instanceof AxiosError) {
            // pass
        } else if (response) onDownloadSuccess(response, values);
        setState({...state, reportDownloadResponse: response});
    };

    /**
     * onError
     * @param {FieldErrors} errors
     * @return {void}
     */
    const onError=(errors:FieldErrors):void => {
        enqueueSnackbar("Fill Required Fields", {variant: "info"});
    };

    /**
     * onItemSelection
     * @param {any} item
     * @return {void}
     */
    const onItemSelection=(item:any):void => {
        const {uniqueId} = REPORTING_LEVEL[activeReportingLevel];
        if (state.bucket.find((bucketItem) => bucketItem[uniqueId] === item[uniqueId])) {
            enqueueSnackbar(`${item[uniqueId]} Item already exists!`, {variant: "info"});
            return;
        }
        setState((prevState:State) => resolveSubmitStatus(prevState, formReturn.getValues(), [...state.bucket, item._meta])); // eslint-disable-line no-underscore-dangle
    };

    /**
     * addSpace
     * @param {React.ReactElement} content
     * @return {React.ReactElement}
     */
    const addSpace=(content:React.ReactElement):React.ReactElement => (
        <Box sx={{margin: {xl: "25px", lg: "25px", md: "25px", sm: "25px", xs: "25px"}}}>
            {content}
        </Box>
    );

    return (
        <Box>
            {/* prompt dialog */}
            <Dialog
                dialog="PROMPT_DIALOG"
                status={context.state.dialog}
                title="Warning: Data Loss on Exit"
                content={(
                    <Box>
                        <Typography>
                            If you leave this page, you will be redirected and all the data you&apos;ve searched for will be lost.
                            <br />
                            <br />
                            <b>Note: </b>
                            If you want to download the data you&apos;ve searched for so far, please do so before exiting or switching between different reporting levels (e.g., from meters to property).
                            Switching will still result in the loss of all current data.
                        </Typography>
                    </Box>
                )}
                actions={[
                    {label: "Cancel", onClick: (args:React.MouseEvent):void => context.setState({..._.cloneDeep(context.state), dialog: "NONE", to: undefined})},
                    {
                        label: "Continue",
                        onClick: (args:React.MouseEvent):void => {
                            if (context.state.to) navigate(context.state.to, {state: {from: window.location.pathname}});
                            else navigate("/");
                        },
                    },
                ]}
            />
            {/* backdrop */}
            <Backdrop open={state.reportDownloadResponse!==undefined && loading} />
            {/* intro */}
            <Box sx={{marginBottom: "32px"}}><BasicDataExportIntro /></Box>
            {/* form */}
            <BasicExportForm
                formReturn={formReturn}
                onItemSelection={onItemSelection}
                loading={loading}
                onError={onError}
                onSubmit={{callback: onDownload, disabled: state.isSubmitDisabled}}
                fields={formFields}
                disabled={state.reportDownloadResponse?.status===200}
                dataGrid={Object.keys(REPORTING_LEVEL).filter((i:any) => i!=="agency").includes(activeReportingLevel) ? (
                    <DataGrid
                        title={REPORTING_LEVEL[activeReportingLevel].title}
                        column={REPORTING_LEVEL[activeReportingLevel].columns}
                        rows={state.bucket}
                        onRowsDelete={onRowsDelete}
                        onRowDelete={onRowDelete}
                        disabled={state.reportDownloadResponse?.status===200}
                        getRowId={(row:GridRowModel) => row[REPORTING_LEVEL[activeReportingLevel].uniqueId]}
                    />
                ):null}
            />
            {/* Alert */}
            {state.reportDownloadResponse?.status===200 && addSpace(<Alert title="Success" body="Report has been exported." severity="success" />)}
            {state.reportDownloadResponse && "status" in state.reportDownloadResponse && state.reportDownloadResponse?.status!==200 && addSpace(<Alert title="500 Internal Server Error" body="Something went wrong." severity="error" />)}
        </Box>
    );
}

export default BasicDataExport;
