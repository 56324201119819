import React, {useState, useCallback, useEffect} from "react";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import {CircularProgress, Grid, Box, Theme, ThemeProvider, createTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
import {Routes as RoutesSwitch, Route} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import api from "../api";
import {APP_PATHS} from "../config";
// components
import MenuBar from "../components/layout/MenuBar";
import ProtectedRoute from "../components/generics/ProtectedRoute";
// views
import Http404 from "./Http404";
import Splash from "./Splash";
import Context from "../components/generics/Context";
import Footer from "../components/layout/Footer";
import BasicDataExport from "./BasicDataExport";
import Faq from "./Faq";

const theme:Theme=createTheme({
    palette: {
        primary: {
            main: "#0A5796",
        },
        secondary: {
            main: "#182A33",
        },
        info: {
            main: "#1B5E20",
        },
    },
});

/**
 * Routes
 * @return {React.ReactElement}
 */
function Routes():React.ReactElement {
    const {isLoading, isAuthenticated, getAccessTokenSilently, logout}:Auth0ContextInterface<User>= useAuth0();
    const [token, setToken]:any=useState(null);

    /**
     * resolveToken
     * @return {void}
     */
    const resolveToken=useCallback(
        async ():Promise<void> => {
            if (!isAuthenticated) return;
            try {
                const value=await getAccessTokenSilently();
                setToken(value);
                api.defaults.headers.common.Authorization=`Bearer ${value}`;
                api.defaults.headers.common["Content-Type"]="application/json";
            } catch (e:any) {
                console.log(e);
                logout({logoutParams: {returnTo: `${window.location.origin}/?signout=true`}});
            }
        },
        [setToken, getAccessTokenSilently, isAuthenticated, logout],
    );

    // resolve token from auth0
    useEffect(() => { resolveToken(); }, [resolveToken, setToken]);

    /**
     * constructRoutes
     * @return {React.ReactElement}
     */
    const constructRoutes=():React.ReactElement => (
        <RoutesSwitch>
            <Route path={APP_PATHS.HOME} element={<ProtectedRoute component={<Splash />} public />} />
            <Route path={APP_PATHS.BASIC_DATA_EXPORT} element={<ProtectedRoute component={<BasicDataExport />} />} />
            <Route path={APP_PATHS.FAQ} element={<ProtectedRoute component={<Faq />} />} />
            <Route path="*" element={<ProtectedRoute component={<Http404 />} />} />
        </RoutesSwitch>
    );

    return (
        <Context value={{token}}>
            <SnackbarProvider {...{anchorOrigin: {horizontal: "center", vertical: "top"}}}>
                <ThemeProvider theme={theme}>
                    {/* menu-bar */}
                    <MenuBar />
                    {/* main */}
                    <Box style={{backgroundColor: grey[100], paddingBottom: (isAuthenticated ? "250px" : "0"), minHeight: "100vh"}}>
                        {isLoading
                            ?(
                                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{minHeight: "75vh"}}>
                                    <Grid item xs={3}><CircularProgress /></Grid>
                                </Grid>
                            )
                            :constructRoutes()}
                    </Box>
                    {/* footer */}
                    {isAuthenticated && <Footer />}
                </ThemeProvider>
            </SnackbarProvider>
        </Context>
    );
}

export default Routes;
