import React from "react";
import {Box} from "@mui/material";
import {useNavigate, useLocation} from "react-router-dom";
import {ChevronLeft, NewReleasesOutlined} from "@mui/icons-material";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";

/**
 * Http404
 * @return {React.ReactElement}
 */
function Http404():React.ReactElement {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Box sx={{paddingTop: 8}}>
            <EmptyStateBlock
                icon={NewReleasesOutlined}
                header="Page not found..."
                subHeaders={["Sorry, but the page you requested does not exist."]}
                primaryAction={{
                    onClick: (args:React.MouseEvent) => {
                        // check if previous item exists in history stack
                        if (location.key !== "default") navigate(-1);
                        else navigate("/");
                    },
                    label: "Back",
                    startIcon: <ChevronLeft />,
                }}
            />
        </Box>
    );
}

export default Http404;
