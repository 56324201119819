import React from "react";
import {Box, Grid, List, ListItem, Typography, useTheme, Theme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import DCAS_LOGO from "../../static/DCAS-Logo.png";
import {MAIN_NAVIGATION, navigateExternal, resolveNavigationItem} from "./MenuBar";
import {NavigationItemType} from "../../types";
import {APP_PATHS, WHITE} from "../../config";
import WithSpace from "./WithSpace";
import {useContext} from "../generics/Context";

export const FOOTER_NAVIGATION:NavigationItemType[] = [
    ...MAIN_NAVIGATION,
    {
        key: "BASIC_DATA_EXPORT",
        label: "Basic Data Export",
        path: APP_PATHS.BASIC_DATA_EXPORT,
    },
    {
        key: "PRIVACY_POLICY",
        label: "Privacy Policy",
        path: APP_PATHS.PRIVACY_POLICY,
        external: true,
    },
    {
        key: "TERMS_OF_USE",
        label: "Terms of Use",
        path: APP_PATHS.TERMS_OF_USE,
        external: true,
    },
    {
        key: "ACCESSIBILITY",
        label: "Accessibility",
        path: APP_PATHS.ACCESSIBILITY,
        external: true,
    },
];

interface constructListItemProps {
    keys: string[],
    sx: {
        listItem?: object,
        link?: object,
        fontWeight?: number | string,
    }
}

const PRIMARY_NAV_KEYS = {
    sx: {
        listItem: {
            "&:not(:last-child)": {
                marginBottom: "10px",
            },
        },
    },
    keys: ["HOME", "BASIC_DATA_EXPORT", "FAQ"],
};

const QUICK_LINKS_KEYS:constructListItemProps = {
    sx: {
        listItem: {
            "&:not(:last-child)": {
                borderRight: "1px solid white",
            },
        },
        link: {
            padding: "0 10px",
        },
    },
    keys: ["DEM_PORTAL", "AGENCY_PORTAL", "ENERGY_TOOLS"],
};
const SECONDARY_NAV_KEYS:constructListItemProps = {
    sx: {
        listItem: {
            "&:not(:last-child)": {
                borderRight: "2px solid white",
            },
        },
        link: {
            padding: "0 10px",
        },
    },
    keys: ["PRIVACY_POLICY", "TERMS_OF_USE", "ACCESSIBILITY"],
};

/**
 * Footer
 * @return {React.ReactElement}
 */
function Footer():React.ReactElement {
    const theme:Theme=useTheme();
    const context=useContext();
    const navigate=useNavigate();

    /**
     * onNavClick
     * @param {NavigationItemType} item
     * @param {React.MouseEvent<HTMLElement>} args
     * @returns {void}
     */
    const onNavClick=(item: NavigationItemType, args:React.MouseEvent<HTMLElement>):void => {
        if (item.path && !item.external) {
            const n:NavigationItemType|undefined=resolveNavigationItem(window.location.pathname);
            if (n?.onDialogClick) context.setState({..._.cloneDeep(context.state), dialog: "PROMPT_DIALOG", to: item.path});
            else navigate(item.path as string, {state: {from: window.location.pathname}});
        } else if (item.path && item.external) navigateExternal(item.path as string);
    };

    /**
     * constructListItems
     * @param {constructListItemProps} listItem
     * @returns {React.ReactElement}
     */
    const constructListItems=(listItem:constructListItemProps):any => (
        listItem.keys.map((item:string) => (
            <ListItem disablePadding disableGutters sx={{width: "auto", ...listItem.sx.listItem}} key={item}>
                <Box
                    sx={{cursor: "pointer", color: "white", textDecoration: "none", ...listItem.sx.link}}
                    onClick={(args:React.MouseEvent<HTMLElement>):void => { onNavClick(FOOTER_NAVIGATION.find((i) => i.key === item) as NavigationItemType, args); }}
                >
                    <Typography lineHeight={1} fontWeight={listItem.sx.fontWeight||700} fontSize={14}>{FOOTER_NAVIGATION.find((i) => i.key === item)?.label}</Typography>
                </Box>
            </ListItem>
        ))
    );

    return (
        <WithSpace sx={{
            height: "250px",
            backgroundColor: theme.palette.secondary.main,
            padding: "54px 0px",
            position: "absolute",
            bottom: "0",
            width: "100%",
        }}
        >
            <Grid container>
                <Grid item xs={4} sx={{textAlign: "left"}}>
                    <Box sx={{marginBottom: "32px"}}>
                        <List disablePadding>
                            {constructListItems(PRIMARY_NAV_KEYS)}
                        </List>
                    </Box>
                    <Box>
                        <Typography color="white" fontWeight={700} fontSize={14}>Quick Links</Typography>
                        <List disablePadding sx={{display: "flex", marginTop: "10px", marginLeft: "-10px"}}>
                            {constructListItems(QUICK_LINKS_KEYS)}
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={4} sx={{textAlign: "center"}}>
                    <Box
                        component="img"
                        sx={{
                            height: "auto",
                            width: "250px",
                            mixBlendMode: "lighten",
                        }}
                        alt="DCAS"
                        src={DCAS_LOGO}
                    />
                </Grid>
                <Grid item xs={4} sx={{textAlign: "right", display: "flex", flexDirection: "column"}}>
                    <Box sx={{alignSelf: "flex-end", marginBottom: "32px", maxWidth: "260px", color: WHITE, fontWeight: "700", fontSize: "14px", "& span": {display: "block", marginBottom: "3px"}, "& address": {fontStyle: "normal"}}}>
                        <address>
                            {["City Of New York", "2024 All Rights Reserved", "NYC is a trademark and service mark of the City of New York USA"].map((s:string) => <span key={s}>{s}</span>)}
                        </address>
                    </Box>
                    <Box sx={{textAlign: "right"}}>
                        {/* Horizontal List */}
                        <List sx={{display: "flex", justifyContent: "flex-end", marginRight: "-10px"}}>
                            {constructListItems(SECONDARY_NAV_KEYS)}
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </WithSpace>
    );
}

export default Footer;
