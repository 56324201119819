import React, {useContext as useContextBase, useMemo, createContext, SetStateAction, Dispatch, useState} from "react";
import {DialogType} from "../../types";

export const APP_CONTEXT = createContext({});

interface Props {
    children:React.ReactNode,
    value:{
        token:string
    }
}

interface State{
    dialog:DialogType
    to:string|undefined
}

/**
 * useContext
 * @return {any}
 */
export const useContext=():any => useContextBase(APP_CONTEXT) as Props;

/**
 * Context
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Context(props:Props):React.ReactElement {
    const [state, setState]:[SetStateAction<State>, Dispatch<State>]=useState<State>({
        dialog: "NONE",
        to: undefined,
    });
    return (
        <APP_CONTEXT.Provider
            value={useMemo(
                () => ({
                    token: props.value.token,
                    state,
                    setState,
                }),
                [
                    props.value.token,
                    state,
                    setState,
                ],
            )}
        >
            {props.children}
        </APP_CONTEXT.Provider>
    );
}

export default Context;
