import React from "react";
import {Box} from "@mui/material";

interface Props {
    children:React.ReactElement[]|React.ReactElement
    sx?:any
}

/**
 * WithSpace
 * @param {Props} props
 * @return {React.ReactElement}
 */
function WithSpace({children, sx}: Props):React.ReactElement {
    return (
        <Box
            sx={{
                paddingLeft: {xs: 2, sm: 3, md: 4, lg: 10, xl: 12},
                paddingRight: {xs: 2, sm: 3, md: 4, lg: 10, xl: 12},
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}

export default WithSpace;
