import React, {useMemo, useCallback, useEffect} from "react";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import {AxiosError} from "axios";
import {slackCall} from "../components/generics/useCall";
import PostSplash from "../components/layout/PostSplash";
import PreSplash from "../components/layout/PreSplash";

/**
 * Splash
 * @return {React.ReactElement}
 */
function Splash():React.ReactElement {
    const {isAuthenticated, loginWithRedirect, user}:Auth0ContextInterface<User>= useAuth0();
    const keys:any=useMemo(() => ({}), []);

    /**
     * hash
     * @param {string} v
     * @return {number}
     */
    const hash=(v:string):number => {
        let h=0;
        if (v.length===0) return h;
        for (let i=0; i<v.length; i+=1) {
            const char=v.charCodeAt(i);
            h=((h<<5)-h)+char; // eslint-disable-line no-bitwise
            h&=h; // eslint-disable-line no-bitwise
        }
        return h;
    };

    /**
     * onKeyDown
     * @param {KeyboardEvent} args
     * @return {Promise<void>}
     */
    const onKeyDown=useCallback(async (args:KeyboardEvent):Promise<void> => {
        const shortcut=Object.keys(keys)
            .reduce((a:any, v:any) => ([...a, (keys[v] && v)]), [])
            .filter((i:any) => i!==false)
            .join("")
            .split("")
            .sort((a:any, b:any) => (a<b?-1:1))
            .join("");
        const isShortcut=hash(shortcut)===669266970;

        if (args.type==="keydown") keys[args.code]=true;
        else if (args.type==="keyup") keys[args.code]=false;

        if (isShortcut) await slackCall({status: 400, message: "This is a test", response: {data: "@zxc"}} as AxiosError, user as User);
    }, [keys, user]);

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown, false);
        document.addEventListener("keyup", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
            document.removeEventListener("keyup", onKeyDown, false);
        };
    }, [onKeyDown]);

    if (isAuthenticated) return <PostSplash />;
    return <PreSplash loginWithRedirect={loginWithRedirect} />;
}

export default Splash;
