import React from "react";
import {Backdrop as BackdropBase, CircularProgress} from "@mui/material";

interface Props{
    open:boolean
}
/**
 * Backdrop
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Backdrop(props:Props):React.ReactElement {
    return (
        <BackdropBase
            sx={{color: "#fff", zIndex: (t:any) => t.zIndex.drawer+1}}
            open={props.open}
        >
            <CircularProgress color="inherit" />
        </BackdropBase>
    );
}

export default Backdrop;
