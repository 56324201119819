import React from "react";
import axios, {AxiosError, AxiosResponse} from "axios";
import {BASE_ROUTE} from "./config";

const instance= axios.create();

/**
 * get
 * @param {string} url
 * @param {string} baseUrl
 * @return {Promise<AxiosResponse>}
 */
export const get=async (url:string, baseUrl?:string):Promise<AxiosResponse> => {
    /**
     * helper
     * @param {string} nextUrl
     * @param {AxiosResponse} payload
     * @return {Promise<AxiosResponse>}
     */
    const helper=async (nextUrl:string, payload:AxiosResponse):Promise<AxiosResponse> => {
        // make call
        const call:AxiosError|AxiosResponse=await instance.get(`${nextUrl}`).then((r:AxiosResponse) => r).catch((e:AxiosError) => e);
        // terminate if error
        if (call instanceof AxiosError) return Promise.reject(call as AxiosError);
        // accumulate results
        const temp={
            ...call,
            data: {
                ...call.data,
                results: Object.keys(payload).length===0
                    ?call.data.results
                    :(payload as AxiosResponse).data?.results.concat(...call.data.results),
            },
        };
        // recursively call if next_url exists
        if (temp.data.next_url) return helper(`${baseUrl||BASE_ROUTE}${temp.data.next_url}`, temp);
        return temp;
    };
    return helper(url, {} as AxiosResponse);
};

export default instance;
