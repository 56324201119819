import _ from "lodash";
import {Field} from "../components/generics/inputs";
import {ViewMode} from "../types";

type ViewProp="readOnly"|"disabled";

/**
 * asyncForEach
 * @param {any[]} array
 * @param {any} callback
 * @return {Promise<void>}
 */
const asyncForEach= async (array:any[], callback:any):Promise<void> => {
    for (let index = 0; index < array.length; index++) { // eslint-disable-line no-plusplus
        await callback(array[index], index, array); // eslint-disable-line no-await-in-loop
    }
};

/**
     * resolveOptionKeyLabel
     * @param {string} label
     * @param {[{key:string, label:string}]} keys
     * @return {string} key
     */
const resolveOptionKeyLabel=(label:string, keys:{key:string, label:string}[]):string|undefined => keys.find((key) => key.label === label)?.key;

/**
 * mutateProp
 * @param {any} options
 * @param {ViewMode} viewMode
 * @param {ViewProp} prop
 * @return {void}
 */
const mutateProp=(options:any, viewMode:ViewMode, prop:ViewProp):void => {
    if (options && (prop in options)) { options[prop]=((["DISABLED_MODE"].includes(viewMode))); } // eslint-disable-line no-param-reassign
};

/**
 * alterFieldsViewMode
 * @param {any} fields
 * @param {ViewMode} viewMode
 * @param {ViewProp} prop
 */
const alterFieldsViewMode=(fields:any, viewMode:ViewMode, prop:ViewProp="readOnly"):any => {
    // deeply clone form fields
    const clone:any=_.cloneDeep(fields);
    // iterate over and update readOnly fields
    Object.keys(clone).forEach((key:string) => {
        clone[key].forEach((field:Field) => {
            mutateProp(field.autocompleteOptions, viewMode, prop);
        });
    });
    return clone;
};

export {
    asyncForEach,
    resolveOptionKeyLabel,
    alterFieldsViewMode,
};

export * from "./BasicDataExportHandlers";
