import React, {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {Box} from "@mui/material";
import {useAuth0, Auth0ContextInterface, User, withAuthenticationRequired} from "@auth0/auth0-react";
import {styled} from "@mui/material/styles";
import {APP_PATHS} from "../../config";
import Breadcrumbs from "../layout/Breadcrumbs";
import {resolveNavigationItem} from "../layout/MenuBar";
import WithSpace from "../layout/WithSpace";

/**
 * replaceEmail
 * @param {string} s
 * @param {string} e
 * @return {string}
 */
const replaceEmail=(s:string, e:string):string => s?.replace(/<EMAIL>/g, e);

const MESSAGES:any={
    signout: "You have signed out.",
    signin: "Signed in as <EMAIL>.",
    inactive: "Session Timeout!",
};

export const AppBarSpacer = styled("div")(({theme}) => ({
    [theme.breakpoints.up("xs")]: {minHeight: "80px"},
    [theme.breakpoints.up("lg")]: {minHeight: "100px"},
}));

interface ProtectedRouteProps {
    component:React.ReactElement
    public?:boolean
}

/**
 * ProtectedRoute
 * @param {ProtectedRouteProps} props
 * @return {React.ReactElement}
 */
function ProtectedRoute(props:ProtectedRouteProps):React.ReactElement {
    const isHome=window.location.pathname===APP_PATHS.HOME;
    const {user}:Auth0ContextInterface<User>= useAuth0();
    const search:any=useMemo(() => new URLSearchParams(window.location.search), []);
    const navigate=useNavigate();

    // trigger snackbar based on URL params
    useEffect(() => {
        // resolve access_denied
        if (search.has("error")) {
            enqueueSnackbar(search.get("error_description"), {variant: "error", transitionDuration: {enter: 200, exit: 1000}, autoHideDuration: 10000});
            navigate(APP_PATHS.HOME);
        }
        // resolve "signin"
        if (search.has("signin")) {
            enqueueSnackbar(replaceEmail(MESSAGES.signin, user?.email || ""), {variant: "success"});
            search.delete("signin");
            navigate(window.location.pathname);
        }
        // resolve "signout"
        if (search.has("signout")) {
            enqueueSnackbar(MESSAGES.signout, {variant: "success"});
            if (search.has("inactive")) enqueueSnackbar(MESSAGES.inactive, {variant: "error"});
            search.delete("signout");
            search.delete("inactive");
            navigate(APP_PATHS.HOME);
        }
    }, [search, user?.email, navigate]);

    // NOTE: used to force scrolling to page top
    useEffect(() => { window.scrollTo(0, 0); });

    // content
    const main=(
        <Box>
            {/* break */}
            <AppBarSpacer />
            {/* breadcrumb */}
            {(!isHome && resolveNavigationItem(window.location.pathname)!==undefined) && <Breadcrumbs />}
            {/* main content */}
            <WithSpace sx={isHome?{padding: "0px !important"}:undefined}><Box sx={isHome?undefined:{marginTop: "64px", marginBottom: "64px"}}>{props.component}</Box></WithSpace>
        </Box>
    );

    // escape if route is public
    if (props.public===true) return main;

    // validate via Auth0 if route is private
    const Component=withAuthenticationRequired(() => main);
    return <Component />;
}

export default ProtectedRoute;
